




































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import SystemStore from '@/api/models/SystemStore'
import OzonStore from '@/api/models/OzonStore'
import Account from '@/api/models/Account'
import Store from '@/api/models/Store'
import { RootStateMutations } from '@/store/mutations'
import Connection from '@/api/models/Connection'
import { getBrandName } from '@/shared/components/helper'

@Component({})
export default class StockSettings extends Vue {
    private EMPTY_CODE = '-1'

    @Prop({ type: Boolean, default: false })
    readonly updateStock!: boolean

    @Prop({ type: Array, default: () => [] })
    readonly stores!: Array<Store>

    @Getter('getCurrentAccount')
    account!: Account

    @Getter('getSystemStores')
    systemStores!: Array<SystemStore>

    @Getter('getOzonStores')
    ozonStores!: Array<OzonStore>

    @Getter('connection')
    connection!: Connection

    @Action('loadStores')
    loadStores!: (account: number) => void

    @Watch('needUpdateStock')
    onNeedUpdateStockChanged (value: boolean) {
        this.$emit('updateStock', value)
    }

    private storeMapping: Array<Store> = []
    private needUpdateStock = false
    private error = false

    get fullBrand (): string {
        return getBrandName(this.connection)
    }

    get systemStoresWithEmpty () {
        const store: SystemStore = {
            code: this.EMPTY_CODE,
            name: this.$t('empty').toString()
        }
        this.systemStores.unshift(store)

        return this.systemStores
    }

    async created (): Promise<void> {
        this.$store.commit(RootStateMutations.setLoading, true)

        try {
            this.needUpdateStock = this.updateStock
            this.storeMapping = this.stores

            if (this.account && this.account.id) {
                await this.loadStores(this.account.id)
            }
        } finally {
            this.$store.commit(RootStateMutations.setLoading, false)
        }
    }

    private change (systemCode: string, ozonCode: number): void {
        if (systemCode === this.EMPTY_CODE) {
            this.remove(ozonCode)
        } else {
            this.edit(systemCode, ozonCode)
        }

        this.error = false
        this.$emit('changeStoreMapping', this.storeMapping)
    }

    private remove (ozonCode: number): void {
        this.storeMapping.forEach((store: Store, index: number) => {
            if (store.ozonCode === ozonCode) {
                this.storeMapping.splice(index, 1)
            }
        })
    }

    private edit (systemCode: string, ozonCode: number): void {
        const store = { systemCode, ozonCode }

        const exist = this.storeMapping.find((store: Store) => store.ozonCode === ozonCode)
        const other = this.storeMapping.find((store: Store) => store.systemCode === systemCode)

        if (other) {
            this.$emit('throwError', this.$t('settings.stock.oneToOneAlert').toString())
        } else if (!exist) {
            this.storeMapping.push(store)
        } else {
            exist.systemCode = systemCode
        }
    }

    private value (ozonCode: number): string {
        return this.storeMapping.find((store: Store) => store.ozonCode === ozonCode)?.systemCode ?? this.EMPTY_CODE
    }

    public validate (): boolean {
        if (this.needUpdateStock && this.ozonStores.length > 0 && this.storeMapping.length === 0) {
            this.error = true

            return false
        }

        return true
    }
}
