
































import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import BaseLayout from '@/shared/components/BaseLayout.vue'
import StockSettings from '@/shared/components/StockSettings.vue'
import Account from '@/api/models/Account'
import Store from '@/api/models/Store'
import AccountSettings from '@/api/models/AccountSettings'
import StoresSettings from '@/api/models/StoresSettings'
import AlertType from '@/api/models/AlertType'

@Component({
    components: {
        BaseLayout,
        StockSettings
    }
})
export default class Stock extends Vue {
    @Getter('getCurrentAccount')
    account!: Account

    @Action('updateAccountSettings')
    updateAccountSettings!: (settings: AccountSettings) => Promise<void>

    @Action('updateStores')
    updateStores!: (stores: StoresSettings) => Promise<void>

    private mapping: Array<Store> = []
    private updateStock!: boolean
    private alert: AlertType = {
        alertType: 'error',
        alertMessage: ''
    }

    get needUpdateStock (): boolean {
        return this.account && this.account.settings ? this.account.settings.updateStock : false
    }

    private changeStoreMapping (mapping: Array<Store>): void {
        this.mapping = mapping
    }

    private updateStockHandler (updateStock: boolean): void {
        this.updateStock = updateStock
    }

    private async save (): Promise<void> {
        this.closeAlert()
        const settings = this.account.settings
        if (settings) {
            settings.updateStock = this.updateStock
            try {
                await this.updateAccountSettings(settings)

                const stores: StoresSettings = {
                    stores: this.mapping
                }

                await this.updateStores(stores)

                this.showAlert(this.$t('response.successful').toString(), 'success')
            } catch (err) {
                let message = ''
                if (
                    Array.isArray(err.response.data.details) &&
                    err.response.data.details.length > 0
                ) {
                    message = err.response.data.details[0]
                } else {
                    message = err.response.data.message ?? err
                }
                this.showAlert(message, 'error')
            }
        }
    }

    private showAlert (message: string, type = 'error'): void {
        this.alert.alertType = type
        this.alert.alertMessage = message
    }

    private closeAlert (): void {
        this.alert.alertMessage = ''
    }
}
